import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {
  Button,
  Grid,
  IconButton,
  InputBase,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { apiGetPlan, apiInviteUser, apiUninviteUser } from "../../api";
import LabeledLoader from "../../components/labeled-loader";
import Plan from "../../models/plan";
import { validateEmail } from "../../helpers/email";
import { apiTokenCookieName } from "../../helpers/config";

import "./index.scss";

export const UserManager = () => {
  const [plan, setPlan] = useState<Plan | null>(null);
  const [inviteEmail, setInviteEmail] = useState("");
  const [cookies] = useCookies([apiTokenCookieName]);
  const highlightsAPIToken = cookies[apiTokenCookieName];

  const refreshPlan = () => {
    apiGetPlan(highlightsAPIToken)
      .then((resp) => {
        setPlan(resp.data);
        setInviteEmail("");
      })
      .catch((err) => {
        alert(err?.response?.data?.message || "Error getting your plan.");
      });
  };
  useEffect(() => {
    if (highlightsAPIToken && !plan) {
      refreshPlan();
    }
  }, []);

  const owner = plan && plan.currentPlanOwner;
  const isOwner = !owner || plan.user.email === owner.email;
  const userCount = plan && plan.currentUsage.userCount
  const isOnMultiUserPlan = typeof userCount === "number" && userCount > 1
  const validInviteEmail = validateEmail(inviteEmail);

  const sendInvite = () => {
    apiInviteUser(highlightsAPIToken, inviteEmail)
      .then(() => refreshPlan())
      .catch((err) => {
        const msg = err?.response?.data?.message;
        if (msg.includes("That is not a valid email")) {
          alert("Hmm, it looks like that isn’t a valid email address. Please correct the email address or try entering a different email address.");
        } else alert(msg || "Error inviting user.");
      });
  };

  return (
    <Grid container className="user-manager">
      {!plan && (
        <Grid item xs={12}>
          <LabeledLoader label="...retrieving your data..." />
        </Grid>
      )}
      {plan && (
        <Grid item container xs={12} md={8} lg={6}>
          <Grid item container xs={12} spacing={2}>
            {isOnMultiUserPlan && isOwner && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    Like to invite others to Loop?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <InputBase
                    className="user-manager__invite-email"
                    fullWidth
                    value={inviteEmail}
                    placeholder="peer@acme.com"
                    onChange={(evt) => setInviteEmail(evt.currentTarget.value)}
                    onKeyPress={(evt) => {
                      const isEnter = evt.code === "Enter";
                      isEnter && validInviteEmail && sendInvite();
                    }}
                  />
                  <Button
                    className="user-manager__invite-btn"
                    disabled={!validInviteEmail}
                    variant="outlined"
                    onClick={() => sendInvite()}
                  >
                    Send Invite
                  </Button>
                </Grid>
              </>
            )}
            {isOnMultiUserPlan && !isOwner && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    Want to add colleagues?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  Ask your Loop plan owner to submit an invite!
                </Grid>
              </>
            )}
            {!isOnMultiUserPlan && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    Like to invite others to Loop?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  Inviting your colleagues to Loop requires a Team Plan or higher.
                  {" "}
                  <a href="/pricing"><span className="user-manager__upgrade-link">Upgrade Now</span> {">>"}</a>
                </Grid>
              </>
            )}
            {plan.invites && plan.invites.length > 0 && (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <strong>Invited</strong>
                  </Grid>
                  {plan.invites &&
                    plan.invites.map(({ email }) => (
                      <Grid
                        item
                        xs={12}
                        key={email}
                        className="user-manager__invited"
                      >
                        <div className="user-manager__invited-email">
                          {email}
                        </div>
                        {isOwner && (
                          <IconButton
                            className="user-manager__uninvite-btn"
                            size="small"
                            onClick={() => {
                              apiUninviteUser(highlightsAPIToken, email)
                                .then(() => {
                                  refreshPlan();
                                })
                                .catch((err) => {
                                  alert(
                                    err?.response?.data?.message ||
                                    "Error uninviting user."
                                  );
                                });
                            }}
                          >
                            <Close />
                          </IconButton>
                        )}
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default UserManager;
