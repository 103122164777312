import { FC } from "react";
import { PageStatus } from "../hooks/useSetup";
import { SuccessIcon, ErrorIcon, CheckingIcon } from "../icons";

type CheckName =
  | "enableRecordSpeaker"
  | "enableSaveToCloud";

type CheckStatus = "pass" | "fail" | "checking";

const settingsNames: string[] = [
  "enableRecordSpeaker",
  "enableSaveToCloud",
];

const settingsNamesText: Omit<
  Record<CheckName, string>,
  "hasRequiredFeatures"
> = {
  enableRecordSpeaker: "Enable Record Speaker",
  enableSaveToCloud: "Enable Save to Cloud",
};

interface SetupCheckProps {
  label: string;
  status?: CheckStatus;
}

const SetupCheck: FC<SetupCheckProps> = ({ label, status }) => {
  return (
    <li className="setup__checklist-item">
      {status === "pass" && <SuccessIcon />}
      {status === "fail" && <ErrorIcon />}
      {status === "checking" && <CheckingIcon />}
      <span className="setup__checklist-item-name">{label}</span>
    </li>
  );
};

interface SetupChecklistProps {
  pageStatus: PageStatus;
  settings: Record<string, boolean>;
}

export const SetupChecklist: FC<SetupChecklistProps> = ({
  pageStatus,
  settings,
}) => {
  if (settings && Object.keys(settings).length > 0) {
    return (
        <ul className="setup__checklist">
          {settingsNames
            .filter((it) => settings != null && Object.keys(settings).includes(it))
            .map((name) => {
              let status: CheckStatus;
              if (["checking", "fixing"].includes(pageStatus)) {
                status = "checking";
              } else {
                status = settings[name] ? "pass" : "fail";
              }

              return (
                <SetupCheck
                  label={settingsNamesText[name as CheckName]}
                  key={name}
                  status={status}
                />
              );
          })}
        </ul>
      );
  }
  return <></>;
};
