import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Grid } from "@material-ui/core";
import { apiZoomProcessRecording, apiZoomRecordings } from "../../api";
import LabeledLoader from "../../components/labeled-loader";
import { getZoomError } from "../../helpers/zoom";
import ZoomError from "../../components/zoom-error";
import ZoomMeetingsViewer from "../../components/zoom-meetings-viewer";
import Results from "../results";

import "./index.scss";
import { accessTokenCookieName } from "../../helpers/config";
import { getParamRefresh } from "../../helpers/url";

export const Meetings = () => {
  const [loadingMeetings, setLoadingMeetings] = useState(false);
  const [meetings, setMeetings] = useState(null);
  const [meetingUuid, setMeetingUuid] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [cookies, , removeCookie] = useCookies([accessTokenCookieName]);
  const savedToken = cookies[accessTokenCookieName];

  useEffect(() => {
    setLoadingMeetings(true);
    apiZoomRecordings(savedToken)
      .then((d) => {
        const data = d.data;
        if (data) {
          setMeetings(data);
        }
      })
      .catch((error) => {
        const msg = getZoomError(error);
        if (
          msg?.indexOf("token is expired") !== -1 ||
          msg?.indexOf("Invalid access token") !== -1
        ) {
          removeCookie(accessTokenCookieName, { path: "/" });
        } else {
          setErrorMessage(msg || "Could not retrieve meetings from Zoom.");
        }
      })
      .finally(() => setLoadingMeetings(false));
  }, []);

  return (
    <Grid container className="user-manager">
      {loadingMeetings && (
        <Grid item xs={12}>
          <LabeledLoader label="...retrieving your meetings..." />
        </Grid>
      )}
      {errorMessage && <ZoomError errorMessage={errorMessage} />}
      {meetings && !meetingUuid && (
        <ZoomMeetingsViewer
          token={savedToken}
          initMeetingsRes={meetings}
          onSubmit={setMeetingUuid}
        />
      )}
      {meetingUuid && <Processor meetingUuid={meetingUuid} />}
    </Grid>
  );
};

const Processor = ({ meetingUuid }: any) => {
  const [processToken, setProcessToken] = useState<any>(null);

  const [cookies] = useCookies([accessTokenCookieName]);
  const savedToken = cookies[accessTokenCookieName];
  const refresh = getParamRefresh() === 'true'

  useEffect(() => {
    setProcessToken(null);
    apiZoomProcessRecording(savedToken, meetingUuid, refresh)
      .then((res) => {
        setProcessToken(res.data);
      })
      .catch((ex) => {
        const msg = ex?.response?.data?.message || "";
        if (msg.includes("Cannot create a meaningful Loop for a meeting that is less than")) {
          alert("Hi there! Sorry, Loop doesn’t process meetings less than one minute in length. Please select another meeting you would like Loop to summarize.");
        } else alert(`Could not process recording. ${msg}`);
      });
  }, []);

  return (
    <Grid container>
      {!processToken && (
        <Grid item container xs={12} justifyContent="center">
          <LabeledLoader label="...processing meeting..." />
        </Grid>
      )}
      {processToken && (
        <Results
          key={processToken}
          processToken={processToken}
        />
      )}
    </Grid>
  );
};

export default Meetings;
