import { Grid } from "@material-ui/core";
import { useEffect } from "react";
import { apiAcceptInvite } from "../../api";
import LabeledLoader from "../../components/labeled-loader";
import { getUrlParam } from "../../helpers/url";

import "./index.scss";

const RSVP = () => {
  const rsvpToken = getUrlParam("rsvp");

  useEffect(() => {
    if (rsvpToken) {
      apiAcceptInvite(rsvpToken)
        .then(() => (window.location.href = "/"))
        .catch(() => {
          alert("Sorry, we could not mark the invite as accepted.");
        });
    }
  }, []);

  return (
    <Grid container justifyContent="center" className="rsvp">
      <LabeledLoader label="...accepting invite..." />
    </Grid>
  );
};

export default RSVP;
