import "./index.scss";

const stepLabels = ["Step 1", "Step 2", "Step 3"];

type StepsProps = {
  currentStepIndex: number,
};

const Steps = ({ currentStepIndex }: StepsProps) => {
  return (
    <div className="steps">
      {stepLabels.map((s: string, i) => {
        const isCurrentStep = currentStepIndex === i;
        return (
          <div className="steps__step-wrapper" key={i}>
            <div className={`steps__step-circle${isCurrentStep ? " steps__step-circle-dark" : ""}`}></div>
            <span className="steps__step-label">{isCurrentStep ? s : ""}</span>
          </div>
        );
      })}
    </div>
  )
};

export default Steps;
