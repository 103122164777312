import { createContext, FC, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { apiZoomMe } from "../../api";
import { accessTokenCookieName } from "../../helpers/config";

interface ZoomUserContextType {
  me: {
    first_name: string;
    last_name: string;
    email: string;
    timezone: string;
    pic_url: string;
    job_title: string;
    location: string;
    account_id: string;
    zoom_one_type: number;
  } | null;
  loading: boolean;
}

export const ZoomUserContext = createContext<ZoomUserContextType>({
  me: null,
  loading: true,
});

export const ZoomUserProvider: FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [me, setMe] = useState<any>(null);
  const [{ [accessTokenCookieName]: token }] = useCookies([accessTokenCookieName]);

  useEffect(() => {
    if (!token) return;

    if (!me && !loading) {
      setLoading(true);
      apiZoomMe(token)
        .then((d) => setMe(d.data))
        .catch(() => setMe({ first_name: "there" }))
        .finally(() => setLoading(false));
    }
  }, [me, loading, token, setLoading]);

  return (
    <ZoomUserContext.Provider
      value={{
        me,
        loading,
      }}
    >
      {children}
    </ZoomUserContext.Provider>
  );
};

export const useZoomUser = () => {
  return useContext(ZoomUserContext);
};
