import { useState, useCallback, useEffect } from "react";
import { useCookies } from "react-cookie";
import { SetupProps } from "..";
import {
  apiZoomSettings,
  apiFixZoomSettings,
  Setting,
} from "../../../api";
import { useAnalytics } from "../../../components/analytics";
import { accessTokenCookieName, returningUserCookieName } from "../../../helpers/config";

const FOUR_HUNDRED_DAYS_IN_SECONDS = 34560000;

export type PageStatus =
  | "empty"
  | "readyToConnect"
  | "checking"
  | "checkingTransitional"
  | "allChecksPassingPendingRecommendations"
  | "needsFixing"
  | "fixing"
  | "saving"
  | "errorDuringCheck"
  | "errorDuringFix";

export enum SetupStep {
  Connect = "connect",
  Zoom = "zoom",
  Sharing = "sharing",
}

export type SettingType = Exclude<SetupStep, SetupStep.Connect>

export const setupSteps = [SetupStep.Connect, SetupStep.Zoom, SetupStep.Sharing];

export type RequiredSettingsState = Record<SettingType, Record<string, boolean>>;

export interface SettingWithInput {
  selected: boolean;
  value: string;
}

export interface AuxiliarySettingsState {
  zoom: Record<string, boolean>;
  sharing: Record<string, boolean | SettingWithInput>;
}

const defaultRequiredSettingsState: RequiredSettingsState = {
  zoom: {
    enableRecordSpeaker: false,
    enableSaveToCloud: false,
    hasRequiredFeatures: false,
  },
  sharing: {},
};

const defaultAuxiliarySettingsState: AuxiliarySettingsState = {
  zoom: {
    enableAutoRecording: false,
  },
  sharing: {
    enableShareWithJustMe: true,
    enableNotifyAllMeetingParticipants: false,
    alwaysCc: {
      selected: false,
      value: ""
    },
    alwaysBcc: {
      selected: false,
      value: ""
    },
  },
};

const defaultIsInvalidState = {
  alwaysCc: false,
  alwaysBcc: false,
}

// these are not real settings, but we display them as if they are
export const fakeSettings = ["enableShareWithJustMe"];
const settingsByType: Record<SettingType, string[]> = {
  zoom: ["enableRecordSpeaker", "enableSaveToCloud", "hasRequiredFeatures", "enableAutoRecording"],
  sharing: ["enableShareWithJustMe", "enableNotifyAllMeetingParticipants", "alwaysCc", "alwaysBcc"],
};

const eventNames: Record<string, Record<SettingType, string>> = {
  settingsCheckFailed: {
    zoom: "Setup - Dead End - Settings Check Failed",
    sharing: "Sharing - Dead End - Settings Check Failed",
  },
  settingsChecked: {
    zoom: "Setup - Settings Checked",
    sharing: "Sharing - Settings Checked",
  },
  upgradeRequired: {
    zoom: "Setup - Dead End - Upgrade Required",
    sharing: "Sharing - Dead End - Upgrade Required",
  },
  couldNotFix: {
    zoom: "Setup - Dead End - Couldn't Fix Settings",
    sharing: "Sharing - Dead End - Couldn't Fix Settings",
  },
  settingsChanged: {
    zoom: "Setup - Settings Changed",
    sharing: "Sharing - Settings Changed",
  },
}

export const useSetup = ({ currentStepIndex, handleStepChange }: SetupProps) => {
  const currentStep: SetupStep = setupSteps[currentStepIndex];

  const [requiredSettings, setRequiredSettings] =
    useState<Record<string, boolean>>(defaultRequiredSettingsState[currentStep as SettingType]);
  const [auxiliarySettings, setAuxiliarySettings] =
    useState<Record<string, boolean | SettingWithInput>>(defaultAuxiliarySettingsState[currentStep as SettingType]);
  const [pageStatus, setPageStatus] = useState<PageStatus>("empty");
  const [isInvalid, setIsInvalid] = useState<Record<string, boolean>>(defaultIsInvalidState);
  const [showUpgradeRequired, setShowUpgradeRequired] = useState(false);
  const [showApprovalRequired, setShowApprovalRequired] = useState(false);
  const [allowProgress, setAllowProgress] = useState(false);
  const { track } = useAnalytics();

  const [cookies] = useCookies([accessTokenCookieName]);
  const token = cookies[accessTokenCookieName];

  const checkSettings = useCallback(async () => {
    if (currentStep === SetupStep.Connect) return setPageStatus("readyToConnect");
    if (currentStep === SetupStep.Zoom) setPageStatus("checking");
    if (currentStep === SetupStep.Sharing) setPageStatus("checkingTransitional");
    const response = await apiZoomSettings(token);

    if (response.status !== 200) {
      setRequiredSettings(defaultRequiredSettingsState[currentStep as SettingType]);
      setPageStatus("errorDuringCheck");
      track({
        eventName: eventNames.settingsCheckFailed[currentStep as SettingType],
        properties: {
          status: response.status,
          response: response.data,
        },
      });
      return;
    }

    let needsFixing = false;
    let hasRequiredFeatures: boolean = false;
    const requiredSettings: Record<string, boolean> = {};
    const auxiliarySettings: Record<string, boolean | SettingWithInput> = {};
    const data = response.data as {
      setting: string;
      status: boolean;
      required?: boolean;
      values?: string[];
    }[];

    for (const item of data) {
      if (item.setting === "hasRequiredFeatures") {
        hasRequiredFeatures = item.status;
      }
      const isRequired = item.required ?? true;
      needsFixing = needsFixing || (isRequired && !item.status);

      if (Object.keys(defaultRequiredSettingsState[currentStep as SettingType]).includes(item.setting)) {
        requiredSettings[item.setting] = item.status;
      } else if (Object.keys(defaultAuxiliarySettingsState[currentStep as SettingType]).includes(item.setting)) {
        let setting: boolean | SettingWithInput = item.status
        if (item.values) {
          setting = { selected: item.status, value: item.values.join(", ") }
        }
        auxiliarySettings[item.setting] = setting;
      }
    }

    for (const fake of fakeSettings) {
      if (Object.keys(defaultRequiredSettingsState[currentStep as SettingType]).includes(fake)) {
        requiredSettings[fake] = true
      } else if (Object.keys(defaultAuxiliarySettingsState[currentStep as SettingType]).includes(fake)) {
        auxiliarySettings[fake] = true
      }
    }

    if (currentStep !== SetupStep.Zoom) {
      hasRequiredFeatures = true;
    }

    setRequiredSettings(requiredSettings);
    setAuxiliarySettings(auxiliarySettings);
    setPageStatus(
      needsFixing ? "needsFixing" : "allChecksPassingPendingRecommendations"
    );
    track({
      eventName: eventNames.settingsChecked[currentStep as SettingType],
      properties: data.reduce(
        (out, curr) => ({ ...out, [curr.setting]: curr.status }),
        {}
      ),
    });

    if (!hasRequiredFeatures) {
      setShowUpgradeRequired(true);
      track({
        eventName: eventNames.upgradeRequired[currentStep as SettingType],
        properties: {
          status: response.status,
          response: response.data,
        },
      });
    }

    setAllowProgress(hasRequiredFeatures)
  }, [token, currentStep, track]);

  const fixSettings = useCallback(async () => {
    const includeAuxiliary =
      pageStatus === "allChecksPassingPendingRecommendations";

    setPageStatus(includeAuxiliary ? "saving" : "fixing");

    try {
      const settings: Setting[] = [];
      if (includeAuxiliary) {
        const relevantSettingEntries = Object.entries(auxiliarySettings)
          .filter(([settingName]) => settingsByType[currentStep as SettingType].includes(settingName) && !fakeSettings.includes(settingName))

        for (const [settingName, settingValue] of relevantSettingEntries) {
          if (typeof settingValue === "boolean") {
            settings.push({
              setting: settingName,
              selected: settingValue,
            });
          } else {
            settings.push({
              setting: settingName,
              selected: settingValue.selected,
              values: settingValue.value ? settingValue.value.split(",").map((e) => e.trim()) : [],
            });
          }
        }
      }

      await apiFixZoomSettings(token, settings);
    } catch (error) {
      let errorStatus;
      const axiosError = error as any;
      track({
        eventName: eventNames.couldNotFix[currentStep as SettingType],
        properties: {
          status: axiosError.response?.status,
          response: axiosError.response?.data,
        },
      });
      if (axiosError.response?.data?.message === "Missing permissions") {
        setShowApprovalRequired(true);
        errorStatus = "needsFixing" as PageStatus;
      } else {
        errorStatus = "errorDuringFix" as PageStatus;
      }

      setPageStatus(errorStatus);
      return errorStatus;
    }

    const newRequiredSettings = {
      enableRecordSpeaker: true,
      enableSaveToCloud: true,
      hasRequiredFeatures: true,
    };

    setRequiredSettings(newRequiredSettings);

    if (!includeAuxiliary) {
      setPageStatus("allChecksPassingPendingRecommendations");
    } else {
      if (setupSteps.length - 1 > currentStepIndex) handleStepChange(currentStepIndex + 1)
    }

    track({
      eventName: eventNames.settingsChanged[currentStep as SettingType],
      properties: {
        ...newRequiredSettings,
        ...auxiliarySettings
      },
    });
  }, [pageStatus, track, currentStep, auxiliarySettings, token, handleStepChange, currentStepIndex]);

  const handleNextButtonClicked = useCallback(async () => {
    switch (pageStatus) {
      case "readyToConnect":
        handleStepChange(currentStepIndex + 1);
        break;
      case "needsFixing":
      case "errorDuringFix":
        void fixSettings();
        break;
      case "allChecksPassingPendingRecommendations":
        if (currentStep as SettingType === SetupStep.Sharing) {
          const errorStatus = await fixSettings();
          if (!errorStatus) {
            document.cookie = `${returningUserCookieName}=1; max-age=${FOUR_HUNDRED_DAYS_IN_SECONDS}`
            window.location.href = "https://heyloop.ai/confirmation";
          }
        } else {
          void fixSettings();
        }
        break;
      case "errorDuringCheck":
        void checkSettings();
        break;
      default:
        break;
    }
  }, [checkSettings, currentStep, currentStepIndex, fixSettings, handleStepChange, pageStatus]);

  const handleDismissAlert = useCallback(() => {
    setShowApprovalRequired(false);
    setShowUpgradeRequired(false);
  }, []);

  useEffect(() => {
    void checkSettings();
  }, [checkSettings]);

  return {
    showApprovalRequired,
    showUpgradeRequired,
    handleNextButtonClicked,
    handleDismissAlert,
    allowProgress,
    isInvalid,
    setIsInvalid,
    requiredSettings,
    auxiliarySettings,
    setAuxiliarySettings,
    pageStatus,
    currentStep,
    setPageStatus,
  };
};
