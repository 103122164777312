import { Grid } from "@material-ui/core";
import "./index.scss";

const Support = () => {
  return (
    <Grid container>
      <Grid item xs={12} md={6} lg={8}>
        <div className="support">
          <h1>Customer Support</h1>
          <p>
            You can reach us 8am-6pm PST by sending us an email at
            support@heyloophq.com or, if you prefer, by filling out this{" "}
            <a href="https://www.heyloop.ai/contact-us">contact form</a>.
          </p>
          <p>We look forward to hearing from you!</p>
        </div>
      </Grid>
    </Grid>
  );
};

export default Support;
