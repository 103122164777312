import { useCookies } from "react-cookie";
import { accessTokenCookieName } from "../../helpers/config";
import "./index.scss";

type Props = { errorMessage: string };
const ZoomError = ({ errorMessage }: Props) => {
  const [, , removeCookie] = useCookies([accessTokenCookieName]);

  return (
    <div className="zoom-error">
      Error connecting with Zoom: {errorMessage}
      <br />
      Please{" "}
      <a
        onClick={() => removeCookie(accessTokenCookieName, { path: "/" })}
        href={window.location.origin}
      >
        try again
      </a>
      .
    </div>
  );
};

export default ZoomError;
