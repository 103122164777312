import React, { useEffect, useState } from "react";
import { Routes, Route, Outlet, Navigate, useSearchParams } from "react-router-dom";
import { apiZoomHighlightsToken, apiZoomToken } from "../api";
import Landing from "./landing";
import { useCookies } from "react-cookie";
import TopBanner from "../components/top-banner";
import Started from "./started";
import TOS from "./tos";
import Support from "./support";
import Pricing from "./pricing";
import UserManager from "./user-manager";
import Setup from "./setup";
import { getZoomError, getZoomForwardUrl, zoomUrl } from "../helpers/zoom";
import Meetings from "./meetings";
import LabeledLoader from "../components/labeled-loader";
import { getUrlParam } from "../helpers/url";
import { accessTokenCookieName, apiTokenCookieName, returningUserCookieName } from "../helpers/config"

import rightGlow from "./glow1.svg";
import leftGlow from "./glow2.svg";
import "./index.scss";
import RSVP from "./rsvp";
import { Grid, Typography } from "@material-ui/core";
import Button from "../components/button";
import Footer from "../components/footer";
import Logo from "../components/logo";
import Steps from "../components/steps";
import Nav from "../components/navigation";
import SignIn from "../components/sign-in";

const LoopApp = () => {
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("code");

  const [cookies, setCookie, removeCookie] = useCookies([
    accessTokenCookieName,
    apiTokenCookieName,
    returningUserCookieName,
  ]);
  const savedToken = cookies[accessTokenCookieName];
  const apiToken = cookies[apiTokenCookieName];
  const isReturning = cookies[returningUserCookieName] === "1";
  const THREE_DAYS_IN_SECONDS = 259200;

  useEffect(() => {
    if (code && !savedToken) {
      apiZoomToken(code, getZoomForwardUrl(code))
        .then((d: any) => {
          const token = d.data;
          if (token) {
            setCookie(accessTokenCookieName, token, { maxAge: THREE_DAYS_IN_SECONDS });
            searchParams.delete("code");
            setSearchParams(searchParams)
          }
        })
        .catch((error) => {
          alert(getZoomError(error) || "Could not connect to Zoom.");
        });
    }
  }, [code, savedToken, searchParams, setCookie, setSearchParams]);
  
  useEffect(() => {
    if (savedToken && !apiToken) {
      apiZoomHighlightsToken(savedToken)
        .then((d) => {
          setCookie(apiTokenCookieName, d.data, { maxAge: 3600 });
        })
        .catch(() => {
          removeCookie(accessTokenCookieName, { path: "/" });
          removeCookie(apiTokenCookieName, { path: "/" });
        });
    }
  }, [apiToken, removeCookie, savedToken, setCookie]);

  const tokens = savedToken && apiToken;

  const RSVPLanding = () => {
    return (
      <>
        {!tokens && (
          <>
            {(code || savedToken) && <LabeledLoader label="...logging in..." />}
            {!code && !savedToken && (
              <Grid item xs={12} md={6} lg={8}>
                <Typography variant="h4">Welcome to Loop</Typography>
                <p>
                  Loop instantly transforms any Zoom meeting into a
                  beautiful summary you may share with your team or your
                  clients! Loop captures key elements of every meeting to
                  help you keep everyone in the loop.
                </p>
                <Button
                  href={zoomUrl()}
                  label="Sign in using Zoom to Accept Invite"
                />
              </Grid>
            )}
          </>
        )}
        {tokens && <RSVP />}
      </>
    );
  };

  const SignInLanding = () => {
    return (
      <>
        {(code || savedToken) && <LabeledLoader label="...logging in..." />}
        {!code && !savedToken && <SignIn />}
      </>
    );
  };

  const TokenCheck = () => {
    return tokens ? <Outlet /> :
      <>
        <Background />
        <TopBanner><Logo /></TopBanner>
        <div className="loop-app__content">
          <SignInLanding />
        </div>
      </>
      ;
  }

  const ReturningCheck = () => {
    return isReturning ? <Landing /> : <Navigate to="/setup" />;
  }

  const handleStepChange = (currentStepIndex: number) => {
    setStepIndex(currentStepIndex);
  };

  const Background = () => {
    return (
      <div className="background">
        <img src={rightGlow} loading="lazy" className="background__right-glow" alt="" />
        <img src={leftGlow} loading="lazy" className="background__left-glow" alt="" />
      </div>
    );
  }

  type AppWrapperProps = {
    includeLogo?: boolean,
    includeSteps?: boolean,
    includeNavLinks?: boolean,
    includeBackground?: boolean,
  };

  const AppWrapper = ({
    includeLogo = false,
    includeSteps = false,
    includeNavLinks = false,
    includeBackground = true,
  }: AppWrapperProps) => {
    return (
      <>
        {includeBackground && <Background />}
        <TopBanner>
          {includeLogo && <Logo linkHome={isReturning} />}
          {includeSteps && <Steps currentStepIndex={stepIndex} />}
          {includeNavLinks && <Nav />}
        </TopBanner>
        <div className="loop-app__content">
          <Outlet />
        </div>
      </>
    );
  };

  return (
    <div className="loop-app">
      <Routes>
        <Route element={<AppWrapper includeLogo includeNavLinks={tokens} />} >
          <Route path="/status" element={<React.Fragment><div className="loop-app">Status: OK</div></React.Fragment>} />
          <Route path="/rsvp" element={<RSVPLanding />} />
          <Route path="/zoom/deauthorize" element={<ZoomDeauthorize />} />
          <Route path="/getstarted" element={<Started />} />
          <Route path="/terms" element={<TOS />} />
          <Route path="/support" element={<Support />} />
        </Route>
        <Route element={<AppWrapper includeLogo includeNavLinks={tokens} includeBackground={false} />} >
          <Route path="/pricing" element={<Pricing />} />
        </Route>
        <Route element={<TokenCheck />}>
          <Route element={<AppWrapper includeLogo includeSteps />} >
            <Route path="/setup" element={<Setup currentStepIndex={stepIndex} handleStepChange={handleStepChange} />} />
          </Route>
          <Route element={<AppWrapper includeLogo includeNavLinks />} >
            <Route path="/users" element={<UserManager />} />
            <Route path="/meetings" element={<Meetings />} />
            <Route path="/" element={<ReturningCheck />} />
          </Route>
        </Route>
      </Routes>
      <Footer />
    </div>
  );
};

const ZoomDeauthorize = () => {
  const msg = getUrlParam("message");
  const [, , removeCookie] = useCookies([accessTokenCookieName, apiTokenCookieName]);

  useEffect(() => {
    removeCookie(accessTokenCookieName, { path: "/" });
    removeCookie(apiTokenCookieName, { path: "/" });
    !msg && setTimeout(() => (window.location.href = "/"), 3000);
  }, []);
  return (
    <div className="loop-app__deauthorize">
      {msg && (
        <p>
          {msg} Please <a href={zoomUrl()}>login again</a> using Zoom.
        </p>
      )}
      {!msg && (
        <React.Fragment>
          <p>You have been logged out.</p>
        </React.Fragment>
      )}
    </div>
  );
};

export default LoopApp;
