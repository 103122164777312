import { Grid, Typography } from "@material-ui/core";
import "./index.scss";

const Started = () => {
  return (
    <Grid container>
      <Grid item xs={12} md={6} lg={8}>
        <div className="started">
          <Typography variant="h4">Getting Started</Typography>
          <p>
            Summarize your Zoom meetings in minutes. Loop takes any meeting you
            record and instantly produces a team-ready, client-facing summary.
            Loop makes every meeting rememberable - and searchable - so you can
            instantly recall who said what clever thing in that meeting a month
            ago.
          </p>
          <Typography variant="h5">Adding and Usage</Typography>
          <p>
            <ol className="started__ol">
              <li>
                Visit{" "}
                <a href="/" target="mmi">
                  Loop
                </a>
              </li>
              <li>Click 'Take Loop for a Spin' or 'Login'</li>
              <li>Sign In (if necessary)</li>
              <li>Check your Zoom Settings</li>
              <li>
                Choose a previously recorded meeting you wish to summarize
              </li>
              <li>Click 'Summarize'</li>
              <li>
                Record a Zoom meeting and get a summary automatically emailed to
                you.
              </li>
            </ol>
          </p>
          <Typography variant="h5">Remove</Typography>
          <p>
            <ol className="started__ol">
              <li>
                Login to your Zoom Account and navigate to the Zoom App
                Marketplace.
              </li>
              <li>
                Click Manage {">>"} Added Apps or search for the "Loop" app.
              </li>
              <li>Click the "Loop" app.</li>
              <li>Click 'Remove'.</li>
            </ol>
          </p>
          <Typography variant="h4">Frequency Asked Questions</Typography>
          <p>
            <strong>What versions of Zoom does this app work with?</strong>
            <br />
            To utilize Loop, you must have either a Zoom Pro, Business,
            Business Plus, or Enterprise account.
          </p>
          <p>
            <strong>
              Do I need to first record a meeting in order to summarize it?
            </strong>
            <br />
            Yes. You can do so using Zoom's record a meeting feature.
          </p>
          <p>
            <strong>How many meetings can I summarize?</strong>
            <br />
            The free plan allows you to receive 5 meeting summaries, you may
            choose to upgrade to a monthly plan for more summaries.
          </p>
          <p>
            <strong>What does this app cost?</strong>
            <br />
            Pricing for the service is located on our{" "}
            <a href="https://www.heyloop.ai/pricing">Pricing</a> page.
          </p>
          <Typography variant="h4">Still have questions?</Typography>
          <p>Anything we can help you with? We'd love to hear from you!</p>
          <p>
            Please contact us through our{" "}
            <a href="https://www.heyloop.ai/contact-us">Contact Us</a> page.
          </p>
        </div>
      </Grid>
    </Grid>
  );
};

export default Started;
