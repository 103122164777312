import { Typography } from "@material-ui/core";

import image from "./authorize-zoom-img.webp";
import "./index.scss";
import Button from "../../components/button";
import { zoomUrl } from "../../helpers/zoom";

const SignIn = () => {
  return (
    <div className="sign-in">
      <div className="sign-in__headline">
        <Typography variant="h1">Hi 👋 Welcome back!</Typography>
      </div>
      <div className="sign-in__content">
        <div className="sign-in__content-left">
          <div className="sign-in__text">
            Please sign in using your Zoom account.
          </div>
          <div className="sign-in__button-container">
            <Button
              label="Sign in using Zoom"
              href={zoomUrl()} />
          </div>
        </div>
        <div className="sign-in__content-right">
          <img
            className="sign-in__image"
            src={image}
            alt="A smiling Black woman with long, wavy hair
            holds out her phone with her right hand
            and points to text on the screen with her left.
            The text is too small to be legible, but it appears to be part of an email from Loop.
            Overlaid are the Loop and Zoom icons, connected by arrows." />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
