import React, { useCallback, useEffect, useState } from "react";
import { apiGetPlan } from "../../api";
import Plan from "../../models/plan";
import { useCookies } from "react-cookie";
import { Typography } from "@material-ui/core";
import LabeledLoader from "../../components/labeled-loader";

import image from "./confirmation-img.webp";
import "./index.scss";
import Button from "../../components/button";
import { apiTokenCookieName, fallbackPlanName } from "../../helpers/config";

const Landing = () => {
  const [plan, setPlan] = useState<Plan | null>(null);
  const [cookies] = useCookies([apiTokenCookieName]);
  const apiToken = cookies[apiTokenCookieName];

  const refreshPlan = useCallback(async () => {
    await apiGetPlan(apiToken)
      .then((resp) => {
        setPlan(resp.data);
      })
      .catch((err) => {
        console.error("failed to get plan data", err, JSON.stringify(err))
        alert(err?.response?.data?.message || "Error getting your plan.");
      });
  }, [apiToken, setPlan]);

  useEffect(() => {
    if (apiToken && !plan) {
      refreshPlan();
    }
  }, [apiToken, refreshPlan, plan]);

  const remaining = plan?.currentUsage ? plan.currentUsage.limit - plan.currentUsage.used : 0;
  const planName = plan?.currentUsage.planName ?? fallbackPlanName;

  return (
    <div className="landing">
      {!plan && <LabeledLoader label="...retrieving data..." />}
      {plan && (
        <>
          <div className="landing__headline">
            <Typography variant="h1">Welcome back!</Typography>
          </div>
          <div className="landing__content">
            <div className="landing__content-left">
              <div className="landing__text">
                You are on the{" "}
                {planName}.
                <br />
                <br />
                The {planName} gives you {plan.currentUsage.limit} meeting Loops each month.
                <br />
                <br />
                You have {remaining}{" "}
                {remaining === 1 ? "meeting" : "meetings"} remaining this month.
                <br />
                <br />
                Don’t forget to hit record on Zoom!
              </div>
              <div className="landing__button-container">
                <Button
                  label="Get more Loops per Month"
                  onClick={() => (window.location.href = "/pricing")} />
              </div>
            </div>
            <div className="landing__content-right">
              <img
                className="landing__image"
                src={image}
                alt="A collage of the Zoom app icon,
                the Zoom record button,
                and a screenshot of four people in a Zoom meeting." />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Landing;
