import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { apiExtractorById, apiExtractorStatus } from "../../api";
import LabeledLoader from "../../components/labeled-loader";
import Button from "../../components/button";

import "./index.scss";

const mappedErrors: Record<string, string> = {
  AUDIO_NOT_FOUND: "We attempted to create a Loop for your meeting, but it appears the recording has been removed.",
  INSUFFICIENT_CONTENT: "That meeting wasn’t long enough to summarize! Try selecting a meeting where more conversation occurred.",
}

// highlights
const _findByType = (highlights: any[], type: string) => {
  return highlights.filter((d: any) => d.type === type);
};

type ResultsProps = {
  processToken: any;
};
const Results = ({ processToken }: ResultsProps) => {
  const [processStatus, setProcessStatus] = useState<any>(null);
  const [processedData, setProcessedData] = useState<any>(null);

  const utterances =
    processedData && _findByType(processedData.highlights, "Utterances");
  const utterancesIn =
    utterances && utterances[0] && JSON.parse(utterances[0].data);
  const storyboardHighlight =
    processedData && _findByType(processedData.highlights, "Storyboard");
  const storyboard =
    storyboardHighlight &&
    storyboardHighlight[0] &&
    JSON.parse(storyboardHighlight[0].data);

  const showStoryboard = true;

  const max = processStatus && processStatus.subExtractorStatuses.length * 100;
  var total = 0;
  processStatus &&
    processStatus.subExtractorStatuses &&
    processStatus.subExtractorStatuses.forEach((s: any) => {
      total += Number(s.status.replace("%", ""));
    });
  const storyboardStatusComplete =
    processStatus &&
    processStatus.subExtractorStatuses.filter(
      (s: any) => s.subExtractorName === "Storyboard"
    )[0]?.status === "100%";
  const completed = storyboard || storyboardStatusComplete || total === max;

  const statusError = processStatus && processStatus.error;
  const displayError = mappedErrors[statusError] || statusError

  const noUtterancesIn = utterancesIn && utterancesIn.length === 0;

  const getPercent = () => {
    const val = Math.floor((total / max) * 100);
    return isNaN(val) ? 0 : val;
  };

  useEffect(() => {
    const updateStatus = () => {
      if (processToken) {
        apiExtractorStatus(processToken.accessToken, processToken.extractorId)
          .then((res) => {
            if (!utterances) {
              apiExtractorById(
                processToken.accessToken,
                processToken.extractorId
              )
                .then((res) => setProcessedData(res.data))
                .catch((ex) => alert(`Could not get processed data. ${ex}.`));
            }
            setProcessStatus(res.data);
          })
          .catch((ex) => alert(`Could not update processing status. ${ex}.`));
      } else {
        alert("Please kick off a process request.");
      }
    };

    if (processToken) {
      if (!processStatus) {
        setTimeout(() => updateStatus(), 100);
      } else {
        // TODO see if we can get rid of the extra call to apiExtractorById
        if (!completed && !statusError) {
          setTimeout(() => updateStatus(), 3000);
        } else if (!storyboard) {
          apiExtractorById(processToken.accessToken, processToken.extractorId)
            .then((res) => {
              setProcessedData(res.data);
              window.scrollTo(0, 0);
            })
            .catch((ex) => {
              alert(`Could not get processed data. ${ex}.`);
            });
        }
      }
    }
  }, [processStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <Grid container spacing={2} className="meeting-coach__results">
        {noUtterancesIn ? (
          <Grid item xs={12}>
            <Typography variant="h4">Silent Meeting?</Typography>
            <div className="storyboard__title">
              The meeting did not have any speakers, please try{" "}
              <a href="/meetings">another one</a>.
            </div>
          </Grid>
        ) : (
          <React.Fragment>
            {showStoryboard && storyboard && (
              <Grid item xs={12}>
                <div className="storyboard">
                  <Typography variant="h4">Meeting Summary</Typography>
                  <div className="storyboard__title">{storyboard.title}</div>
                  <div className="storyboard__description">
                    {`${storyboard.intro
                      }${`\nSome next steps from this meeting:\n${storyboard.nextSteps}`}`
                      .split("\n")
                      .map((d) => (
                        <p key={d}>{d}</p>
                      ))}
                  </div>
                </div>
              </Grid>
            )}
            {!statusError && showStoryboard && !storyboard && (
              <Grid item xs={12}>
                <div style={{ textAlign: "center" }}>
                  <LabeledLoader
                    label={`Generating Loop summary: ${getPercent()}%`}
                  />
                  <br />
                  <p>This might take 1-2 minutes.</p>
                  <p>
                    Don't want to wait? We will also send you an email with the
                    Loop summary when it is ready.
                  </p>
                </div>
              </Grid>
            )}
            {statusError && (
              <Grid item xs={12}>
                <p>{displayError}</p>
              </Grid>
            )}
          </React.Fragment>
        )}
      </Grid>
      <div className="storyboard__button-container">
        <Button
          onClick={() => window.location.reload()}
          label="Summarize another meeting"
          withBackArrow
        />
      </div>
    </React.Fragment>
  );
};

export default Results;
