export const origin = window.location.origin;
export const isLocal = origin.startsWith("http://localhost");
export const isProd = origin.startsWith("https://my");
export const isDev =
  origin.startsWith("https://beta") || origin.startsWith("https://dev");
export const apiBase = isLocal
  ? "http://localhost:8080"
  : isDev
    ? "https://dev-api.obindohighlights.com"
    : "https://api.obindohighlights.com";

let oauthClientId = process.env.REACT_APP_OAUTH_CLIENT_ID;
if (!oauthClientId) {
  oauthClientId =
    isLocal || isDev ? "ORhvu1vTLCC2FYXRYx17w" : "IxLsVkg4SOa3KQoThSl_dg";
}

export const zoomLinkBase = `https://zoom.us/oauth/authorize?response_type=code&client_id=${oauthClientId}&redirect_uri=`;

export const fallbackPlanName = "Loop Plan";

export const accessTokenCookieName = "atp";
export const apiTokenCookieName = "api_token";
export const returningUserCookieName = "return";

export const datadogSettings = {
  applicationId: "59f02d75-5a60-4f79-beb8-797a2495d994",
  clientToken: "pub8f1d9718b3b00ec3d88017ceeb8f66bd",
  site: "datadoghq.com",
  service: "meeting-coach",
  env: isProd ? "prod" : "dev",
  version: process.env.REACT_APP_COMMIT_ID,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input" as const
};
