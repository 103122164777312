import { useState } from "react";

import "./index.scss";

const Nav = () => {
  const [showMenu, setShowMenu] = useState<boolean | null>(null);
  
  const clickHandler = (event: React.MouseEvent<HTMLElement>) => {
    const element = event.target as HTMLInputElement;
    if (!element.matches("a")) setShowMenu(false);
  }
  
  return (
    <>
      <div className="nav__mobile-menu">
          <div
            className="nav__mobile-menu-button"
            onClick={() => setShowMenu(!showMenu)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M3 6h18c.6 0 1-.4 1-1s-.4-1-1-1H3c-.6 0-1 .4-1 1s.4 1 1 1zm18 5H3c-.6 0-1 .4-1 1s.4 1 1 1h18c.6 0 1-.4 1-1s-.4-1-1-1zm0 7H3c-.6 0-1 .4-1 1s.4 1 1 1h18c.6 0 1-.4 1-1s-.4-1-1-1z"></path>
            </svg>
          </div>
        <div
          className={`nav__mobile-menu-background${showMenu ? " nav__mobile-menu-background--show" : ""
            }${!showMenu ? " nav__mobile-menu-background--hide" : ""}`}
          onClick={() => setShowMenu(false)}
        ></div>
      </div>
      <ul className={`nav__menu-items${showMenu ? " nav__menu-items--show" : ""}`} onClick={clickHandler}>
        <li className="nav__menu-item"><a href="/pricing">Upgrade Plan</a></li>
        <li className="nav__menu-item"><a href="/setup">Change Settings</a></li>
        <li className="nav__menu-item"><a href="/users">Add Users</a></li>
        <li className="nav__menu-item"><a href="/meetings">My Zoom Meetings</a></li>
        <li className="nav__menu-item nav__menuspacer"></li>
        <li className="nav__menu-item"><a href="/zoom/deauthorize">Logout</a></li>
      </ul>
    </>
  );
};

export default Nav;
