import axios from "axios";
import { apiBase } from "../helpers/config";

axios.interceptors.response.use(undefined, function (error) {
  if (error?.response?.data?.message === "Your token has expired.") {
    window.location.href = "/zoom/deauthorize?message=Your token expired.";
  }
  return Promise.reject(error);
});

export const apiPostExtractorRequest = (apiToken: string, request: any) =>
  axios.post(`${apiBase}/extractor`, request, {
    headers: { apiToken },
  });

export const apiExtractorStatusDemo = () =>
  axios.get(
    `${apiBase}/extractor/78354bf0-a215-444a-84d4-c1126ecb581b/status`,
    {
      headers: { accessToken: "demo" },
    }
  );

export const apiExtractorByIdDemo = () =>
  axios.get(`${apiBase}/extractor/4f584b2a-abbf-42a0-9fab-4032192dca46`, {
    headers: { accessToken: "demo" },
  });

export const apiExtractorStatus = (accessToken: string, extractorId: string) =>
  axios.get(`${apiBase}/extractor/${extractorId}/status`, {
    headers: { accessToken },
  });

export const apiExtractorShareable = (
  accessToken: string,
  extractorId: string
) =>
  axios.get(`${apiBase}/extractor/${extractorId}/shareable`, {
    headers: { accessToken },
  });

export const apiMapShareable = (token: string) =>
  axios.get(`${apiBase}/extractor/shareable/${token}`);

export const apiExtractorShare = (
  accessToken: string,
  extractorId: string,
  emails: string[],
  meetingId?: string
) =>
  axios.post(
    `${apiBase}/extractor/${extractorId}/share`,
    { emails, meetingId },
    {
      headers: { accessToken },
    }
  );

export const apiExtractorById = (accessToken: string, extractorId: string) =>
  axios.get(`${apiBase}/extractor/${extractorId}`, {
    headers: { accessToken },
  });

export const apiZoomToken = (code: string, redirectUri: string) =>
  axios.post(`${apiBase}/zoom/token`, { code, redirectUri });

export const apiZoomRecordings = (token: string, endDate?: string) =>
  axios.get(
    `${apiBase}/zoom/recordings${endDate ? `?endDate=${endDate}` : ""}`,
    {
      headers: { token },
    }
  );

export const apiZoomMe = (token: string) =>
  axios.get(`${apiBase}/zoom/me`, { headers: { token } });

export const apiZoomSettings = (token: string) =>
  axios.get(`${apiBase}/zoom/settings-status?feature=storyboard`, {
    headers: { token },
  });

export interface Setting {
  setting: string;
  selected: boolean;
  values?: string[]
}

export const apiFixZoomSettings = (token: string, settings: Setting[]) =>
  axios.put(`${apiBase}/zoom/settings?feature=storyboard`, settings, {
    headers: { token },
    validateStatus: (status) => [200, 400].includes(status),
  });

export const apiZoomHighlightsToken = (token: string) =>
  axios.get(`${apiBase}/zoom/highlights-token`, { headers: { token } });

export const apiZoomProcessRecording = (token: string, meetingId: string, refresh: boolean) =>
  axios.post(
    `${apiBase}/zoom/process?meetingId=${encodeURIComponent(meetingId)}&refresh=${encodeURIComponent(refresh)}`,
    {},
    {
      headers: { token },
    }
  );

export const apiZoomParticipants = (token: string, meetingId: string) =>
  axios.get(
    `${apiBase}/zoom/participants?meetingId=${encodeURIComponent(meetingId)}`,
    {
      headers: { token },
    }
  );

export const apiGetPlan = (apiToken: string) =>
  axios.get(`${apiBase}/users/me/plan?type=Automated`, {
    headers: { apiToken },
  });

export const apiAcceptInvite = (rsvpToken: string) =>
  axios.put(`${apiBase}/rsvp`, {}, { headers: { rsvpToken } });

export const apiInviteUser = (apiToken: string, email: string) =>
  axios.put(
    `${apiBase}/users/me/invites`,
    { email },
    { headers: { apiToken } }
  );

export const apiUninviteUser = (apiToken: string, email: string) =>
  axios.put(
    `${apiBase}/users/me/uninvites`,
    { email },
    { headers: { apiToken } }
  );
