import "./index.scss";

const TOS = () => {
  return (
    <div className="tos">
      <h1>Terms of Service</h1>
      <p className="p">
        <strong>Agreement between user and Curious Labs, Inc.</strong>
        <br />
        Welcome! This website (the “Site”) is comprised of various web pages
        operated by Curious Labs, Inc. dba Loop (“Loop”). This Site is offered
        to you conditioned on your acceptance without modification of the terms,
        conditions, and notices contained herein (the “Terms”). Your use of this
        Site constitutes your agreement to all such Terms. Please read these
        terms carefully, and keep a copy of them for your reference.
      </p>
      <p className="p">
        <strong>Privacy</strong>
        <br />
        Your use of Loop is subject to Loop’s Privacy Policy. Please review our
        Privacy Policy, located at http://loop.com/privacy-policy/, which also
        governs the Site and informs users of our data collection practices.
      </p>
      <p className="p">
        <strong>Electronic communications</strong>
        <br />
        Visiting Loop or sending emails to Loop constitutes electronic
        communications. You consent to receive electronic communications and you
        agree that all agreements, notices, disclosures and other communications
        that we provide to you electronically, via email and on the Site,
        satisfy any legal requirement that such communications be in writing.
      </p>
      <p className="p">
        <strong>Your account</strong>
        <br />
        If you use this Site, you are responsible for maintaining the
        confidentiality of your account and password and for restricting access
        to your computer, and you agree to accept responsibility for all
        activities that occur under your account or password. You may not assign
        or otherwise transfer your account to any other person or entity. You
        acknowledge that Loop is not responsible for third party access to your
        account that results from theft or misappropriation of your account.
        Loop and its associates reserve the right to refuse or cancel service,
        terminate accounts, or remove or edit content in our sole discretion.
      </p>
      <p className="p">
        Loop does not knowingly collect, either online or offline, personal
        information from persons under the age of thirteen. If you are under 18,
        you may use http://loop.com only with permission of a parent or
        guardian.
      </p>
      <p className="p">
        <strong>Cancellation/Refund policy</strong>
        <br />
        You may cancel your subscription at any time. However, no refunds are
        made.
      </p>
    </div>
  );
};

export default TOS;
