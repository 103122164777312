const emailPattern = /^\w+([.\-/+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/

export const validateEmail = (mail: string) => {
  if (emailPattern.test(mail)) {
    return true;
  }
  return false;
};

export const emailListIsValid = (emailList: string) => {
  const emailArray = emailList.split(",").map((e) => e.trim())
  if (emailArray.some((e) => !emailPattern.test(e))) return false
  return true
}
