import { ReactNode } from "react";

import "./index.scss";

type TopBannerProps = {
  children?: ReactNode;
};

const TopBanner = ({ children }: TopBannerProps) => {

  return (
    <div className="top-banner">
      {children}
    </div>
  );
};

export default TopBanner;
