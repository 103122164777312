import { datadogRum } from '@datadog/browser-rum';
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { datadogSettings, isLocal } from './helpers/config';
import LoopApp from "./pages/";

import "./index.scss";
import { AnalyticsProvider } from "./components/analytics";
import { getMixpanelDebug, getMixpanelToken } from "./helpers/analytics";
import { ZoomUserProvider } from "./components/user";

if (!isLocal) {
  datadogRum.init(datadogSettings);
  datadogRum.startSessionReplayRecording();
}

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <ZoomUserProvider>
        <AnalyticsProvider
          token={getMixpanelToken()}
          debug={getMixpanelDebug()}
        >
          <BrowserRouter>
            <LoopApp />
          </BrowserRouter>
        </AnalyticsProvider>
      </ZoomUserProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
