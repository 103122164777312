import { FC, ReactFragment } from "react";
import { SettingWithInput, PageStatus, fakeSettings } from "../hooks/useSetup";
import { Box, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { SuccessIcon } from "../icons";
import { emailListIsValid } from "../../../helpers/email";
import React from "react";

type SettingName =
  | "enableAutoRecording"
  | "enableShareWithJustMe"
  | "enableNotifyAllMeetingParticipants"
  | "alwaysCc"
  | "alwaysBcc";

const settingNames: string[] = [
  "enableAutoRecording",
  "enableShareWithJustMe",
  "enableNotifyAllMeetingParticipants",
  "alwaysCc",
  "alwaysBcc"
];

const settingsNamesText:
  Record<SettingName, string> = {
  enableAutoRecording: "Enable Auto Recording (Recommended)",
  enableShareWithJustMe: "Share with just me. I'll forward to people.",
  enableNotifyAllMeetingParticipants: "Automatically share with all participants (Recommended)",
  alwaysCc: "Always cc the following:",
  alwaysBcc: "Always bcc the following:"
};

const enableAutoRecordingDescription = (
  <React.Fragment>
    <div className="setup__multiline-description">Loop is completely hands-free! You just need to hit record on Zoom.</div>
    <div className="setup__multiline-description">You can always stop recording if you don't need a Loop for that meeting.</div>
  </React.Fragment>
);

const enableShareWithJustMeDescription = (
  <React.Fragment>
    <div className="setup__single-line-description">Loop will send its summary minutes after your meeting ends.</div>
  </React.Fragment>
);

const settingsNamesDescription:
  Record<SettingName, ReactFragment> = {
  enableAutoRecording: enableAutoRecordingDescription,
  enableShareWithJustMe: enableShareWithJustMeDescription,
  enableNotifyAllMeetingParticipants: "",
  alwaysCc: "",
  alwaysBcc: ""
}

const placeholders = {
  enableAutoRecording: "",
  enableShareWithJustMe: "",
  enableNotifyAllMeetingParticipants: "",
  alwaysCc: "tom@acme.com, julie@acme.com",
  alwaysBcc: "sales@acme.com, channel@slack.com"
}

interface AuxiliarySelectionProps {
  name: string;
  label: string;
  description: ReactFragment;
  setting: boolean | SettingWithInput;
  placeholder: string;
  isInvalid: Record<string, boolean>,
  setIsInvalid: (invalidField: Record<string, boolean>) => void,
  handleSetting: (setting: boolean | SettingWithInput) => void;
}

const AuxiliarySelection: FC<AuxiliarySelectionProps> = ({
  name,
  label,
  description,
  setting,
  placeholder,
  isInvalid,
  setIsInvalid,
  handleSetting,
}) => {
  if (typeof setting === "boolean") {
    return (
      <li className="setup__auxiliary-item">
        {description !== "" &&
          <Box className="setup__auxiliary-item-description">
            {description}
          </Box>
        }

        {fakeSettings.includes(name) && setting && (
          <div className="setup__auxiliary-static-check">
            <SuccessIcon />
            <span className="setup__auxiliary-static-check-name">{label}</span>
          </div>
        )}
        {!fakeSettings.includes(name) && (<FormControlLabel
          className="setup__auxiliary-item-selection"
          label={<span className="setup__auxiliary-item-name">{label}</span>}
          control={
            <Checkbox
              name={name}
              checked={setting}
              checkedIcon={<CheckBox className="setup__auxiliary-item--selected" />}
              icon={<CheckBoxOutlineBlank className="setup__auxiliary-item--unselected" />}
              color="primary"
              onChange={(event) => {
                handleSetting(event.target.checked)
              }}
            />
          }
        />)}
      </li>
    );
  } else {
    return (
      <li>
        <FormControlLabel
          className="setup__auxiliary-item-selection"
          label={<span className="setup__auxiliary-item-name" id={`${name}-label`}>{label}</span>}
          control={<Checkbox
            name={name}
            id={`${name}-checkbox`}
            checked={!!setting.value || setting.selected}
            checkedIcon={<CheckBox className="setup__auxiliary-item--selected" />}
            icon={<CheckBoxOutlineBlank className="setup__auxiliary-item--unselected" />}
            color="primary"
            onChange={(event) => {
              const value = event.target.checked ? setting.value : ""
              handleSetting({ selected: event.target.checked, value })
              setIsInvalid({ ...isInvalid, [name]: false })
            }}
          />
          }
        />
        <TextField
          className="setup__auxiliary-input"
          error={isInvalid[name]}
          helperText={isInvalid[name] ? "Please make sure your email address is of the proper format." : ""}
          fullWidth
          value={setting.value}
          aria-labelledby={`${name}-label`}
          placeholder={placeholder}
          variant="outlined"
          inputProps={{
            maxLength: 2048,
          }}
          onChange={(event) => {
            handleSetting({ selected: !!event.currentTarget.value, value: event.currentTarget.value })
            // avoid overzealous error state
            if (isInvalid[name]) {
              const inputIsValid = !event.currentTarget.value || emailListIsValid(event.currentTarget.value)
              setIsInvalid({ ...isInvalid, [name]: !inputIsValid })
            }
          }}
          onBlur={(event) => {
            const blurCausedByUnchecking = event.relatedTarget?.id === `${name}-checkbox`
            const inputIsValid = blurCausedByUnchecking || !setting.value || emailListIsValid(setting.value)
            setIsInvalid({ ...isInvalid, [name]: !inputIsValid })
          }}
        />
      </li>
    );
  }
};

interface AuxiliarySelectionsProps {
  pageStatus: PageStatus;
  isInvalid: Record<string, boolean>,
  setIsInvalid: (invalidField: Record<string, boolean>) => void,
  settings: Record<string, boolean | SettingWithInput>;
  setSettings: (settings: Record<string, boolean | SettingWithInput>) => void;
}

export const AuxiliarySelections: FC<AuxiliarySelectionsProps> = ({
  pageStatus,
  isInvalid,
  setIsInvalid,
  settings,
  setSettings,
}) => {
  if (pageStatus !== "allChecksPassingPendingRecommendations" || !settings || Object.keys(settings).length === 0) {
    return (<></>)
  }

  return (
    <ul className="setup__auxiliary">
      {settingNames
        .filter((it) => settings != null && Object.keys(settings).includes(it))
        .map((name) => {
          return (
            <AuxiliarySelection
              label={settingsNamesText[name as SettingName]}
              description={settingsNamesDescription[name as SettingName]}
              setting={settings[name]}
              key={name}
              name={name}
              placeholder={placeholders[name as SettingName]}
              isInvalid={isInvalid}
              setIsInvalid={setIsInvalid}
              handleSetting={(setting) => {
                setSettings({
                  ...settings,
                  [name]: setting,
                })
              }}
            />
          );
        })}
    </ul>
  );
};
