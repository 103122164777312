import { CircularProgress } from "@material-ui/core";

import "./index.scss";

type LabeledLoaderProps = { label: string };
const LabeledLoader = ({ label }: LabeledLoaderProps) => {
  return (
    <div className="labeled-loader">
      <CircularProgress color="inherit" />
      <br />
      {label}
    </div>
  );
};

export default LabeledLoader;
