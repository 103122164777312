import { Button as MUIButton } from "@material-ui/core";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import React from "react";

import "./index.scss";

type Props = {
  withBackArrow?: boolean;
  withForwardArrow?: boolean;
  disabled?: boolean;
  label?: string;
  onClick?: () => void;
  href?: string;
  children?: any;
};

const Button = ({
  withBackArrow = false,
  withForwardArrow = false,
  disabled = false,
  label,
  onClick,
  href,
  children,
}: Props) => {
  return (
    <MUIButton
      className="button"
      disabled={disabled}
      onClick={onClick}
      href={href}
      disableRipple
    >
      {withBackArrow && (
        <React.Fragment>
          <ArrowBack />
          &nbsp;&nbsp;
        </React.Fragment>
      )}
      {label && label}
      {children && children}
      {withForwardArrow && (
        <React.Fragment>
          &nbsp;&nbsp;
          <ArrowForward />
        </React.Fragment>
      )}
    </MUIButton>
  );
};

export default Button;
