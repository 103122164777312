import mixpanel from "mixpanel-browser";
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useZoomUser } from "../user";

declare global {
  interface Window {
    mixpanel: any;
  }
}

export interface AnalyticsEvent {
  eventName: string;
  properties: Record<string, any>;
}

export interface Analytics {
  track: (event: AnalyticsEvent) => void | Promise<void>;
}

export const AnalyticsContext = createContext<Analytics>({
  track: (event: AnalyticsEvent) => {},
});

export interface AnalyticsProviderProps {
  token: string;
  children: ReactNode;
  debug?: boolean;
}

export const AnalyticsProvider: FC<AnalyticsProviderProps> = ({
  token,
  debug,
  children,
}) => {
  const { me } = useZoomUser();
  const [initialized, setInitialized] = useState<boolean>(false);
  const buffered = useRef<AnalyticsEvent[]>([]);

  const track = useCallback(
    (event: AnalyticsEvent): void => {
      if (!token) {
        return;
      }
      if (!initialized) {
        buffered.current = [...buffered.current, event];
      } else {
        mixpanel.track(event.eventName, event.properties);
      }
    },
    [token, initialized]
  );

  useEffect(() => {
    if (!token) {
      return;
    }
    if (!initialized && !window.mixpanel) {
      mixpanel.init(token, { debug: debug ?? false });
    }
    setInitialized(true);
    for (const event of buffered.current) {
      track(event);
    }
  }, [token, debug, track, initialized]);

  useEffect(() => {
    if (!me?.email) return;

    const newIdentifier = me.email.toLowerCase();

    try {
      if (mixpanel.get_distinct_id() !== newIdentifier) {
        console.log("New user session detected, updating analytics identifier");
        mixpanel.identify(me.email.toLowerCase());
      }
    } catch (e) {}
  }, [me?.email]);

  return (
    <AnalyticsContext.Provider value={{ track }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  return useContext(AnalyticsContext);
};
