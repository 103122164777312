import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { apiZoomRecordings } from "../../api";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { getZoomError } from "../../helpers/zoom";
import ZoomError from "../zoom-error";
import { useCookies } from "react-cookie";
import LabeledLoader from "../labeled-loader";
import Button from "../button";

import "./index.scss";
import { useZoomUser } from "../user";
import { accessTokenCookieName } from "../../helpers/config";
import { formatDuration } from "../../helpers/time";

dayjs.extend(advancedFormat);

const processMeetings = (meetings: any): Map<string, any> => {
  const meetingsByDateMap = new Map<string, any>();
  const orderedMeetings =
    meetings &&
    meetings.sort(
      (a: any, b: any) =>
        new Date(b.start_time).getTime() - new Date(a.start_time).getTime()
    );
  orderedMeetings &&
    orderedMeetings.forEach((m: any) => {
      const d = new Date(m.start_time).toDateString();
      if (!meetingsByDateMap.has(d)) {
        meetingsByDateMap.set(d, [m]);
      } else {
        meetingsByDateMap.get(d).push(m);
      }
    });

  return meetingsByDateMap;
};

type ZoomMeetingsViewerProps = {
  token: string;
  initMeetingsRes: any;
  onSubmit: (meetingUuid: string) => void;
};
const ZoomMeetingsViewer = ({
  token,
  initMeetingsRes,
  onSubmit,
}: ZoomMeetingsViewerProps) => {
  const { me, loading: loadingMe } = useZoomUser();
  const [loadingMeetings, setLoadingMeetings] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [meetingUuid, setMeetingUuid] = useState<string | null>(null);
  const [meetingsRes, setMeetingsRes] = useState(initMeetingsRes);
  const [, , removeCookie] = useCookies([accessTokenCookieName]);

  const numMeetings = meetingsRes?.meetings?.length;
  const groupedMeetings = processMeetings(meetingsRes.meetings);
  const meetingDates = Array.from(groupedMeetings.keys());

  const getOlderMeetings = () => {
    setLoadingMeetings(true);
    apiZoomRecordings(token, meetingsRes.fromDate)
      .then((d) => {
        const data = d.data;
        if (data && data) {
          setMeetingsRes(data);
        }
      })
      .catch((error) => {
        const msg = getZoomError(error);
        if (
          msg?.indexOf("token is expired") !== -1 ||
          msg?.indexOf("Invalid access token") !== -1
        ) {
          removeCookie(accessTokenCookieName, { path: "/" });
        } else {
          setErrorMessage(msg || "Could not retrieve meetings from Zoom.");
        }
      })
      .finally(() => setLoadingMeetings(false));
  };

  return (
    <div className="zoom-meetings-viewer">
      <Typography variant="h4">
        Like to summarize a previous meeting?
      </Typography>

      {loadingMe && <LabeledLoader label="...loading data..." />}

      {loadingMeetings && <LabeledLoader label="..loading older meetings..." />}

      {me && (
        <Fragment>
          <div className="zoom-meetings-viewer__title">Hi {me.first_name}!</div>
          {groupedMeetings && (
            <div className="zoom-meetings-viewer__intro">
              {numMeetings > 0
                ? "Here are the meetings I found between"
                : "Sorry we did not find any meeting recordings in your Zoom account between"}{" "}
              {dayjs(meetingsRes.fromDate).format("MMMM Do, YYYY")} and{" "}
              {dayjs(meetingsRes.toDate).format("MMMM Do, YYYY")}.{" "}
              {numMeetings > 0 && (
                <span>
                  <br />
                  Which one should we take a look at?
                </span>
              )}
              <br />
            </div>
          )}
          {groupedMeetings && (
            <RadioGroup
              name="meetingUuid"
              className="zoom-meetings-viewer__meetings"
              onChange={(evt) => setMeetingUuid(evt.target.defaultValue)}
            >
              {meetingDates.map((date: string) => (
                <MeetingGroup
                  key={date}
                  date={date}
                  meetings={groupedMeetings.get(date)}
                />
              ))}
              <div>
                <React.Fragment>
                  {errorMessage && <ZoomError errorMessage={errorMessage} />}

                  {numMeetings <= 0 && (
                    <Button
                      onClick={() => getOlderMeetings()}
                      label="Load Older Recordings"
                    />
                  )}
                  {numMeetings > 0 && (
                    <div className="zoom-meetings-viewer__button-container">
                    <Button
                      disabled={!meetingUuid}
                      onClick={() => onSubmit(meetingUuid || "")}
                      withForwardArrow
                      label="Summarize"
                    />
                    </div>
                  )}
                </React.Fragment>
              </div>
            </RadioGroup>
          )}
        </Fragment>
      )}
    </div>
  );
};

type MeetingGroupProps = {
  date: string;
  meetings: any;
};
const MeetingGroup = ({ date, meetings }: MeetingGroupProps) => {
  const dateMoment = dayjs(date);
  const curYear = dayjs(dayjs()).format("YYYY");
  const dateYear = dateMoment.format("YYYY");
  const days = dayjs(dayjs()).diff(dateMoment, "days");
  const label = ["Today", "Yesterday", dateMoment.format("dddd")][
    Math.min(days, 2)
  ];
  return (
    <div key={date} className="meetings-day">
      <strong>
        {label}, {dateMoment.format("MMMM Do")}
        {dateYear === curYear ? "" : `, ${dateYear}`}
      </strong>
      {meetings.map((m: any) => {
        const formattedDuration = formatDuration(m.duration);
        const label = formattedDuration ? `${m.topic} (${formatDuration(m.duration)})` : m.topic;
        return (
          <div key={m.uuid} className="meeting">
            <FormControlLabel
              label={label}
              key={m.uuid}
              value={m.uuid}
              control={<Radio />}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ZoomMeetingsViewer;
