import { CircularProgress } from "@material-ui/core";

export const CheckingIcon = () => {
  return <CircularProgress style={{ width: "17px", height: "17px" }} />;
};

export const SuccessIcon = () => {
  return (
    <svg
      width="23"
      height="21"
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 12L8.40741 18L21 1" stroke="#F37E01" strokeWidth="3" />
    </svg>
  );
};

export const ErrorIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="11" fill="#D62727" />
      <line
        x1="6.3448"
        y1="6.6552"
        x2="15.5372"
        y2="15.8476"
        stroke="#EAE2B7"
        strokeWidth="2"
      />
      <line
        x1="15.5372"
        y1="6.53718"
        x2="6.3448"
        y2="15.7296"
        stroke="#EAE2B7"
        strokeWidth="2"
      />
    </svg>
  );
};

export const ForwardArrow = () => {
  return (
    <svg
      width="52"
      height="24"
      viewBox="0 0 52 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.0607 13.0607C51.6464 12.4749 51.6464 11.5251 51.0607 10.9393L41.5147 1.39339C40.9289 0.807608 39.9792 0.807608 39.3934 1.39339C38.8076 1.97918 38.8076 2.92893 39.3934 3.51472L47.8787 12L39.3934 20.4853C38.8076 21.0711 38.8076 22.0208 39.3934 22.6066C39.9792 23.1924 40.9289 23.1924 41.5147 22.6066L51.0607 13.0607ZM1.31134e-07 13.5L50 13.5L50 10.5L-1.31134e-07 10.5L1.31134e-07 13.5Z"
        fill="#EAE2B7"
      />
    </svg>
  );
};
