import logo from "./logo.webp";

import "./index.scss";

type LogoProps = {
  linkHome?: boolean,
};

const Logo = ({ linkHome = true }: LogoProps) => {
  return (
    <div className="logo">
      <a href={linkHome ? "/" : "/setup"}><img src={logo} loading="lazy" className="logo__image" alt="Loop logo" /></a>
    </div>
  );
};

export default Logo;
