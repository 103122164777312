import { Box } from "@material-ui/core";
import { FC } from "react";
import "./index.scss";

export const UpgradeRequiredDialogContent: FC = () => {
  return (
    <Box className="setup__alert">
      <div>
        Aw, dang! Loop works with paid Zoom accounts only. It looks like you need to upgrade your Zoom account.
        <br />
        <br />
        If your organization has a paid Zoom account, ask your Zoom administrator to add you to the paid Zoom account.
        <br />
        <br />
        If you don’t currently have a paid Zoom account, you’ll need to upgrade.
        <br />
        <br />
      </div>
      <div className="upgrade-instructions__preamble">To do this:</div>
      <ul>
        <li className="upgrade-instructions__item">Log in to your Zoom account at <a href="https://zoom.us">https://zoom.us</a></li>
        <li className="upgrade-instructions__item">Go to “Account Settings”</li>
        <li className="upgrade-instructions__item">Click “Billing”</li>
        <li className="upgrade-instructions__item">
          Click “Upgrade” and select a paid plan: Zoom Pro, Business, Business Plus, or Enterprise
        </li>
      </ul>
    </Box>
  );
};

export const ApprovalRequiredDialogContent: FC = () => {
  return (
    <div className="setup__alert">
      <div>
        We tried to change your settings and couldn’t. They must be locked by an
        Administrator.
      </div>
      <br />
      <div>
        Talk to your Zoom account administrator to enable these settings.
      </div>
    </div>
  );
};
