import { Box, Dialog } from "@material-ui/core";
import { FC } from "react";
import Button from "../button";

import "./index.scss";

interface AlertDialogProps {
  open: boolean;
  onDismiss: () => void;
}

export const AlertDialog: FC<AlertDialogProps> = ({
  open,
  children,
  onDismiss,
}) => {
  return (
    <Dialog open={open}>
      <Box className="alert-dialog">
        {children}
        <Box textAlign="center" className="alert-dialog__actions">
          <Button label="Got it" onClick={onDismiss} />
        </Box>
      </Box>
    </Dialog>
  );
};
