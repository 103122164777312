export const getMixpanelToken = (): string => {
  const { hostname } = window.location;

  if (process.env.REACT_APP_MIXPANEL_TOKEN) {
    return process.env.REACT_APP_MIXPANEL_TOKEN;
  } else if (hostname.startsWith("beta")) {
    return "b10360b996a51e83207f9387f1c6945d";
  } else if (hostname.startsWith("my")) {
    return "0d2319e6b44fab79cda3b82f770f55e4";
  }

  return "";
};

export const getMixpanelDebug = (): boolean => {
  return process.env.REACT_APP_MIXPANEL_DEBUG === "true";
};
