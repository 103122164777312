import { Grid, Typography } from "@material-ui/core";
import { useZoomUser } from "../../components/user";
import { isLocal, isDev } from "../../helpers/config";

import "./index.scss";

export const Pricing = () => {
  const { me } = useZoomUser();
  const emailAttribute = me?.email ? `customer-email=${me.email}` : "";
  const stripePriceProd = `<stripe-pricing-table pricing-table-id="prctbl_1NNeUXKdih1UFM5mf8msYnQN"
  publishable-key="pk_live_51Ls7xqKdih1UFM5m4YgFNWZchIErrZENpy0ddLAgEY7ZoabuAecV8sjhFCyoeYnulJDhFCbblgdbsx2eTtDtG6RF00r2MoYuLk"
  ${emailAttribute}>
  </stripe-pricing-table>`;
  const stripePriceDev = `<stripe-pricing-table pricing-table-id="prctbl_1NNKOEKdih1UFM5mjbBirYuJ"
  publishable-key="pk_test_51Ls7xqKdih1UFM5mm5qT18Nfiq3IWn6iezXpQpKxp3c7q2czDtHhKwRkIYauhlkEpyE2CHig2NNz9AXvafNBqpaC000lS7zGAb"
  ${emailAttribute}>
  </stripe-pricing-table>`;
  const stripePriceTable = isLocal || isDev ? stripePriceDev : stripePriceProd;

  return (
    <Grid container className="pricing" spacing={2}>
      <Grid item container xs={12} justifyContent="center">
        <Typography variant="h4">
          Pick the plan that supports your goal
        </Typography>
      </Grid>
      <Grid item xs={12}>
        &nbsp;
      </Grid>
      <Grid item xs={12}>
        <div dangerouslySetInnerHTML={{ __html: stripePriceTable }} />
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Typography variant="h5">Frequently Asked Questions</Typography>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <p>
              <strong>What forms of payment do you accept?</strong>
              <br />
              We accept Visa, MasterCard, Discover, and American Express credit
              cards.
            </p>
            <p>
              <strong>Can I change my plan?</strong>
              <br />
              Yes. With Loop, you are never locked into any plan that isn't a
              perfect fit for you. You can upgrade, downgrade, or cancel your
              plan at any time.
            </p>
          </Grid>
          <Grid item xs={12} md={6} className="pricing__second-column">
            <p>
              <strong>
                What are the Zoom account requirements to utilize your service?
              </strong>
              <br />
              To utilize Loop, you must have either a Zoom Pro, Business,
              Business Plus, or Enterprise account.
            </p>
            <p>
              <strong>Do you provide custom plans?</strong>
              <br />
              Yes, our enterprise sales team would be happy to speak with you
              about your needs. Please contact us for more information.
            </p>
            <p>
              <strong>How do I get started?</strong>
              <br />
              Pick a plan above, enter your payment information, and follow the
              prompts to get started right away.
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Pricing;
