import {
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import Button from "../../components/button";
import { SetupChecklist } from "./setup-checklist";
import { PageStatus, SettingType, SetupStep, setupSteps, useSetup } from "./hooks/useSetup";
import { AlertDialog } from "../../components/alert-dialog";
import {
  ApprovalRequiredDialogContent,
  UpgradeRequiredDialogContent,
} from "./dialogs";

import "./index.scss";
import { AuxiliarySelections } from "./auxiliary-selections";
import LabeledLoader from "../../components/labeled-loader";

type CheckTextMap = Record<SettingType, Record<PageStatus, string>>;
type CheckTextsMap = Record<SettingType, Record<PageStatus, string[]>>;

type HeaderPart = "title" | "intro"

type HeaderTextMap = Record<SettingType, Record<HeaderPart, string>>;

const headerText: HeaderTextMap = {
  zoom: {
    title: "You've successfully connected Loop to Zoom.",
    intro: "",
  },
  sharing: {
    title: "Last Step...",
    intro: "",
  },
};

const descriptionText: CheckTextsMap = {
  zoom: {
    empty: [],
    readyToConnect: [],
    checking: [],
    checkingTransitional: [],
    fixing: [],
    saving: [],
    errorDuringCheck: [
      "Hmm, something went wrong while checking your Zoom settings. Please click the button below to try again.",
    ],
    errorDuringFix: [
      "Hmm, something went wrong while trying to fix your Zoom settings. Please click the button below to try again.",
    ],
    allChecksPassingPendingRecommendations: [
      "Now put Loop on autopilot by turning on auto-recording",
    ],
    needsFixing: [
      "Looks like we need to make an update to your Zoom settings for Loop to work its magic. No problem! Click the button below and we’ll take care of it.",
    ],
  },
  sharing: {
    empty: [],
    readyToConnect: [],
    checking: [],
    checkingTransitional: [],
    fixing: [],
    saving: [],
    errorDuringCheck: [],
    errorDuringFix: [
      "An error occurred while trying to update your settings. Please try again",
    ],
    allChecksPassingPendingRecommendations: ["How would you like to share your Loop meeting recaps?"],
    needsFixing: [],
  },
};

const buttonText: CheckTextMap = {
  zoom: {
    empty: "",
    readyToConnect: "",
    checking: "Checking Settings...",
    checkingTransitional: "",
    fixing: "Fixing Settings...",
    saving: "",
    allChecksPassingPendingRecommendations: "Next",
    needsFixing: "Fix My Zoom Settings, Please!",
    errorDuringCheck: "Try Again",
    errorDuringFix: "Try Again",
  },
  sharing: {
    empty: "",
    readyToConnect: "",
    checking: "Checking Settings...",
    checkingTransitional: "",
    fixing: "",
    saving: "",
    allChecksPassingPendingRecommendations: "Done",
    needsFixing: "",
    errorDuringCheck: "Try Again",
    errorDuringFix: "Try Again",
  },
};

export type SetupProps = {
  currentStepIndex: number;
  handleStepChange: (currentStepIndex: number) => void;
};

const Setup = ({ currentStepIndex, handleStepChange }: SetupProps) => {
  const {
    requiredSettings,
    auxiliarySettings,
    allowProgress,
    isInvalid,
    setIsInvalid,
    setAuxiliarySettings,
    pageStatus,
    showApprovalRequired,
    showUpgradeRequired,
    handleNextButtonClicked,
    handleDismissAlert,
    currentStep,
  } = useSetup({ currentStepIndex, handleStepChange });

  let description = descriptionText[currentStep as SettingType]?.[pageStatus];
  if (pageStatus === "needsFixing" && !requiredSettings?.hasRequiredFeatures) {
    description = [];
  }

  const nextButton = (
    <Button
      label={buttonText[currentStep as SettingType]?.[pageStatus] ?? ""}
      onClick={handleNextButtonClicked}
      disabled={
        ["checking", "fixing"].includes(pageStatus) || !allowProgress || Object.values(isInvalid).some(Boolean)
      }
    />
  );

  const isConnectStep = currentStepIndex === setupSteps.indexOf(SetupStep.Connect);
  const useDefaultTitle = !(currentStep === SetupStep.Zoom &&
    (pageStatus === "checking" || pageStatus === "needsFixing" || pageStatus === "fixing"));
  const useTemporaryTitle = currentStep === SetupStep.Zoom && (pageStatus === "checking" || pageStatus === "fixing");

  if (pageStatus === "empty") return null;

  return (
    <>
      <div className="setup">
        {pageStatus === "saving" && (
          <Grid item xs={12}>
            <LabeledLoader label="...saving your settings..." />
          </Grid>
        )}
        {pageStatus === "checkingTransitional" && (
          <Grid item xs={12}>
            <LabeledLoader label="...checking your settings..." />
          </Grid>
        )}
        {isConnectStep && (
          <>
            <div className="setup__headline">
              <Typography variant="h1">Let's make sure Loop is connected to Zoom properly.</Typography>
            </div>
            <div className="setup__content">
              <div className="setup__text">
                This will check the settings on your Zoom account to make sure Loop is able to work its magic.
              </div>
              <div className="setup__button-container">
                <Button
                  label="Check Zoom Connection"
                  onClick={handleNextButtonClicked} />
              </div>
            </div>
          </>
        )}
        {pageStatus !== "saving" && pageStatus !== "checkingTransitional" && !isConnectStep && (
          <>
            <div className="setup__headline">
              <Typography variant="h1" className="setup__header">
                {useTemporaryTitle && "One sec, I’m talking with Zoom..."}
                {currentStep === SetupStep.Zoom && pageStatus === "needsFixing" && "A few Zoom settings need to be adjusted."}
                {useDefaultTitle && headerText[currentStep as SettingType].title}
              </Typography>
            </div>
            <div className="setup__content">
              <div className="setup__text">
                {headerText[currentStep as SettingType].intro}
                <SetupChecklist pageStatus={pageStatus} settings={requiredSettings} />
                {description.length > 0 &&
                  <Box className="setup__description">
                    {description.map((text) => (
                      <Box key={text}>{text}</Box>
                    ))}
                  </Box>
                }
                <AuxiliarySelections
                  pageStatus={pageStatus}
                  isInvalid={isInvalid}
                  setIsInvalid={setIsInvalid}
                  settings={auxiliarySettings}
                  setSettings={setAuxiliarySettings}
                />
              </div>
              <div className="setup__button-container">
                {nextButton}
              </div>
            </div>
          </>
        )}
      </div>
      <AlertDialog
        open={showApprovalRequired || showUpgradeRequired}
        onDismiss={handleDismissAlert}
      >
        {showApprovalRequired && <ApprovalRequiredDialogContent />}
        {showUpgradeRequired && <UpgradeRequiredDialogContent />}
      </AlertDialog>
    </>
  );
};

export default Setup;
