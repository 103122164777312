import { zoomLinkBase } from "../config";

export const getZoomError = (error: any) => {
  if (error.response) {
    console.log(error.response);
    const msg = error.response.data?.message;
    try {
      const parsedMsg = JSON.parse(msg);
      const zoomReason = parsedMsg.reason || parsedMsg.message;
      if (zoomReason) return zoomReason;
    } catch (ex) {}
    return msg;
  } else if (error.request) {
    // no response
    console.log(error.request);
    return "no response";
  } else {
    // never requested
    console.log("Error", error.message);
    return "no request";
  }
};

export const getZoomForwardUrl = (code = "") => {
  var url = window.location.href;
  var path = window.location.pathname;
  if (path !== "/zoom/deauthorize") {
    url = url.replace(`&code=${code}`, "").replace(`code=${code}`, "");
    if (url.endsWith("?")) {
      url = url.replace("?", "");
    }

    return url;
  } else {
    return url;
  }
};

export const zoomUrl = () =>
  `${zoomLinkBase}${encodeURIComponent(getZoomForwardUrl())}`;
